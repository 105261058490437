














import Vue from 'vue'
import AppHeader from './components/AppHeader.vue'
import AppSidebar from './components/AppSidebar.vue'

export default Vue.extend({
  components: {
    AppSidebar,
    AppHeader
  },

  data(): {
    fullSidebar: boolean
  } {
    return {
      fullSidebar: true
    }
  },

  methods: {
    toggleSidebar(status: boolean) {
      this.fullSidebar = status
    }
  }
})
