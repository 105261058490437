

































































import Vue from 'vue'

interface SubMenu {
  label: string
  to: string
  icon: string
}

interface Menu {
  title: string
  open: boolean
  match: string
  menus: SubMenu[]
}

export default Vue.extend({
  props: {
    full: {
      type: Boolean,
      default: false
    }
  },

  data(): {
    menuDatas: Menu[]
    fullSidebar: boolean
  } {
    return {
      menuDatas: [
        {
          title: 'Smart Trading',
          open: true,
          match: 'smart-trading',
          menus: [
            // {
            //   label: 'SmartTrade',
            //   to: '/smart-trading/smart-trade',
            //   icon: 'candle'
            // },
            {
              label: 'Terminal',
              to: '/smart-trading/trades-terminal',
              icon: 'terminal'
            },
            {
              label: 'Positions',
              to: '/smart-trading/positions',
              icon: 'candle'
            },
            {
              label: 'Orders',
              to: '/smart-trading/orders',
              icon: 'order'
            }
          ]
        }
      ],
      fullSidebar: this.full
    }
  },

  computed: {
    path(): string {
      return this.$route.path
    }
  },

  methods: {
    toggleMenu(menu: Menu) {
      // check current route is the same of menu
      const { match } = menu
      menu.open = this.path.includes(match) ? true : !menu.open
    },

    initOpenMenu() {
      // find menu is math
      const menu = this.menuDatas.find((item) => this.path.includes(item.match))
      if (menu) menu.open = true
    },

    toggleFullSidebar() {
      this.fullSidebar = !this.fullSidebar
      this.$emit('toggle-sidebar', this.fullSidebar)
    }
  },

  watch: {
    $route: {
      handler() {
        this.initOpenMenu()
      },
      immediate: true
    },

    full() {
      this.fullSidebar = this.full
    }
  }
})
